
import { Options, Vue } from 'vue-class-component';
import { apiCardSpecGetList, 
         apiCardSpecGetListTotalNum } from '@/services/tpsApi/tpsApiCardSpec';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '@/services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import { exportFile } from '@/services/exportFile';
import { ICardSpecList } from '@/services/tpsApi/models/cardSpec';
import Alert from '@/components/Alert.vue';
import Table from '@/components/Table.vue';
import ActionMenu from '@/components/ActionMenu.vue';
import ChiayiForm from '@/components/ChiayiForm.vue';
import Pagination from '@/components/Pagination.vue';

@Options({
  components: {
    Alert,
    Table,
    ActionMenu,
    ChiayiForm,
    Pagination
  },
})
export default class Chiayi extends Vue {
    $refs!: {
        form: ChiayiForm,
        alert: Alert
    }

    TABLE_HEAD: {} = {
        username: "姓名",
        user_id: "身分證字號",
        in_code: "內碼卡號",
        out_code: "外碼卡號",
        low_income: "低收入戶",
        monthly_free: "每月可免費搭乘次數",
        free_count: "當月已累積免費搭乘次數",
        last_swipe: "最近一次交易時間",
        free_expired: "免費搭乘有效期限"
    };
    
    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;
    
    formVisible: boolean = false;
    cardList: ICardSpecList | Blob = [];
    filter: string | undefined = undefined;
    pageNo: {} = PAGENO;
    
    mounted() {
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        this.initPagination();
    }

    async initPagination() {
        this.pageTotalNum = await apiCardSpecGetListTotalNum({filter: this.filter});
    }

    async showForm(pData: { actionType: string, id: number | null }) {
        this.$refs.form.initForm(pData.actionType,pData.id);
        this.formVisible = true;
    }
    
    hideForm(pData: { actionType: string, alertType: string, msg: string}) {
        this.formVisible = false;
        this.doAlert({alertType: pData.alertType, msg: pData.msg});
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        if(pData.actionType == "create") this.initPagination();
    }

    async refreshTabel(pData: {limit: number, page: number}) {
        if(pData && pData.limit) this.currentLimit = pData.limit;
        if(pData && pData.page) this.currentPage = pData.page;
        pData = Object.assign(pData, {filter:this.filter});
        this.cardList = await apiCardSpecGetList(pData);
    } 

    async download(pData: {output: string}) {
        const res = await apiCardSpecGetList({output: pData.output, filter: this.filter});
        exportFile({ fileName: "嘉義市低收入戶", data: res });
    }

    doSearch(pData: {filter: string}) {
        this.filter = pData.filter;
        this.refreshTabel({limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE});
        this.initPagination();
    }

    doAlert(pData: {alertType: string, msg: string}) {
        this.$refs.alert.doAlert(pData.alertType, pData.msg);
    }
}
