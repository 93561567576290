import tpsApiInstance from "./tpsApiBase";
import {
  ICardSpecList,
  ICardSpecDetail,
  ICardSpecGetListTotalNumRequestParams,
  ICardSpecGetListRequestParams,
  ICardSpecGetDetailRequestParams,
  ICardSpecDetailUpdateRequestParams,
  ICardSpecFreeCountUpdateRequestParams,
  ICardSpecCreateResponseSchema,
  ICardSpecDetailUpdateResponseSchema,
  ICardSpecFreeCountUpdateResponseSchema
} from './models/cardSpec';

const CARDSPEC_PATH = "/listCardSpec";
const CARDSPEC_GET = "/getCardSpec";
const CARDSPEC_UPDATE = "/updateCardSpec";
const CARDSPEC_CREATE = "/createCardSpec";
const CARDSPEC_ADJ = "/updateCardSpecAdj";

export const apiCardSpecGetListTotalNum = async(
  pData?: ICardSpecGetListTotalNumRequestParams): Promise<number> => {
  let query: string = "?count=total";
  if(pData && pData.filter) query+= `&filter=${pData.filter}`;
  console.log("query:",query);
  const res = await tpsApiInstance.get(`${CARDSPEC_PATH}${query}`);
  console.log("total:",res?.data[0]?.total)
  return res?.data[0]?.total;
};

export const apiCardSpecGetList = async(
  pData?: ICardSpecGetListRequestParams): Promise<ICardSpecList | Blob> => {
  let query: string = "?";
  if(pData && pData.limit && pData.page) query += `limit=${pData.limit}&offset=${(pData.page-1)*pData.limit}`;  
  if(pData && pData.filter) query+= `&filter=${pData.filter}`;
  if(pData && pData.output) {
    query += `&output=${pData.output}`;
    console.log("query:",query);
    const res = await tpsApiInstance.get(`${CARDSPEC_PATH}${query}`,{responseType: 'blob'});
    return res?.data as Blob;
  }
  else {
    console.log("query:",query);
    const res = await tpsApiInstance.get(`${CARDSPEC_PATH}${query}`);
    return res?.data as ICardSpecList;
  }
};

export const apiCardSpecGetDetail = async (
  pData: ICardSpecGetDetailRequestParams): Promise<ICardSpecDetail> => {
  const res = await tpsApiInstance.get(`${CARDSPEC_GET}?id=${pData.id}`);
  console.log(res.data);
  return res?.data as ICardSpecDetail;
};

export const apiCardSpecCreate = async (
  data: ICardSpecDetail): Promise<ICardSpecCreateResponseSchema> => {
  console.log(JSON.stringify(data));
  const res = await tpsApiInstance.post(CARDSPEC_CREATE, data);
  return res?.data; //as IComment;
};

export const apiCardSpecDetailUpdate = async (
  pData: ICardSpecDetailUpdateRequestParams
  ): Promise<ICardSpecDetailUpdateResponseSchema> => {
  console.log(JSON.stringify(pData.data));
  const res = await tpsApiInstance.post(`${CARDSPEC_UPDATE}?id=${pData.id}`, pData.data);
  return res?.data;
};

export const apiCardSpecUpdateFreeCount = async (
  pData: ICardSpecFreeCountUpdateRequestParams
): Promise<ICardSpecFreeCountUpdateResponseSchema> => {
  console.log(JSON.stringify(pData));
  const res = await tpsApiInstance.post(
    `${CARDSPEC_ADJ}?in_code=${pData.in_code}&action=${pData.action}`);
  console.log(res.data);
  return res?.data;
};
