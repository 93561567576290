
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit } from "vue-property-decorator";
import { apiCardSpecGetDetail,
         apiCardSpecDetailUpdate,
         apiCardSpecCreate,
         apiCardSpecUpdateFreeCount } from '../services/tpsApi/tpsApiCardSpec';
import { ICardSpecDetail } from '../services/tpsApi/models/cardSpec';
import { arrangeUpdateData } from '../services/arrangeUpdateData';
import DatepickerLite from "vue3-datepicker-lite";
import Alert from '@/components/Alert.vue';
import WarningModal from '@/components/WarningModal.vue';

@Options({
  components: {
    DatepickerLite,
    Alert,
    WarningModal
  },
  emits: ["hide-form"]
})

export default class ChiayiForm extends Vue {
    $refs!: {
        alert: Alert,
        warning: WarningModal
    }

    @Prop({ required: true }) showForm: boolean = false;

    WRITE: number | undefined = undefined; 
    originalData: ICardSpecDetail = {} as any;
    formData: ICardSpecDetail = {} as any;
    actionType: string = "";
    cardID: number = -1;
    //Date picker setting
    datepickerSetting = {
        id: "datepicker",
        class: "md-input myDateInput md-datepicker",
        locale: {
          format: "YYYY-MM-DD HH:mm:ss",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        },
        changeEvent: (value) => {
          this.formData["free_expired"] = value;
        }
    }

    async beforeMount() {
        this.WRITE = this.$store.getters.write;
    }

    async initForm(pActionType, pCardID) {
        this.actionType = pActionType;
        this.cardID = pCardID;
        if(pCardID) {
            this.formData = Object.assign({}, await apiCardSpecGetDetail({id: pCardID}));
            this.originalData = Object.assign({}, this.formData);
            //Fixed free_expired display wrong value when data is null
            if(this.formData.free_expired==null) this.formData.free_expired = "";
        }
        else this.formData = {} as any;
    }

    getWriteProp() {
        if(this.actionType === 'create') return true;
        return this.WRITE===1?true:false;
    }

    async updateDetail(pSave: boolean) {
        if(pSave && Number(this.formData.free_count)>Number(this.formData.monthly_free)) {
            alert("「當月已累積免費搭乘次數」超過「每月可免費搭乘次數」");
        }
        else if(pSave) {
            //Set default modified_time & modified_user
            let dt = new Date(); 
            this.formData["modified_time"] = `${
                (dt.getMonth()+1).toString().padStart(2, '0')}-${
                dt.getDate().toString().padStart(2, '0')}-${
                dt.getFullYear().toString().padStart(4, '0')} ${
                dt.getHours().toString().padStart(2, '0')}:${
                dt.getMinutes().toString().padStart(2, '0')}:${
                dt.getSeconds().toString().padStart(2, '0')}`;
            this.formData["modified_user"] = this.$store.getters.currentUser;
            
            if(this.actionType == "update") {
                let res = await apiCardSpecDetailUpdate({   id: this.cardID, 
                                                            data: arrangeUpdateData(this.formData,this.originalData)});
                if(res.msg === ("ok")) this.hideForm(true);
                else this.hideForm(false);
            }      
            else if(this.actionType == "create") {
                let res = await apiCardSpecCreate(this.formData);
                if(res.msg === ("ok")) this.hideForm(true);
                else this.hideForm(false);
            }    
        }
        else if(pSave == false) {
            this.hideForm(false);
        }
    }

    async updateFreeCount(pAction: string) {
        let free_count = this.formData.free_count;
        if(pAction === "decrease" && this.formData.free_count > 0) {
            this.formData.free_count -= 1;
        }
        else if(pAction === "increase" && this.formData.free_count < this.formData.monthly_free) {
            this.formData.free_count += 1;
        }
        else {
            this.doAlert({alertType: "warning", msg: "超過可搭乘次數範圍"});
        }
    }

    doAlert(pData: {alertType: string, msg: string}) {
        this.$refs.alert.doAlert(pData.alertType, pData.msg);
    } 

    showWarningModal() {
        if(JSON.stringify(this.originalData) !== JSON.stringify(this.formData)) {
            this.$refs.warning.setModalVisible(true);
        }
        else {
            this.updateDetail(false);
        }
    }

    handleCancel() {
        this.hideForm(false);
    }

    @Emit("hide-form")
    hideForm(pSuccess) {
        if(pSuccess) return { actionType: this.actionType, alertType:"success", msg:"成功儲存" };
    }
}
